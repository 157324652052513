import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { NavParams, ModalController, LoadingController, IonInput } from '@ionic/angular';
import { BrickPreviewComponent } from '../brick-preview/brick-preview.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/services/database/orders.service';
import { SwalService } from 'src/app/services/alert/swal.service';
import { FunctionsService } from 'src/app/services/functions/functions.service';
import { BrickChangeComponent } from '../brick-change/brick-change.component';
import { SapService } from 'src/app/services/sap/sap.service';

@Component({
  selector: 'app-order-fest',
  templateUrl: './order-fest.component.html',
  styleUrls: ['./order-fest.component.scss'],
})
export class OrderFestComponent implements OnInit {

  // @ViewChild(IonContent, null) txtPhone: any;
  phone: any
  options = [];
  items: any
  order: any;
  amountSplit: any = 0
  isTotalClubFail: any = false
  isTotalPartnerFail: any = false;
  isTotalBarllenoFail: any = false;
  countProducts: any = 0
  referenceKits: any = []
  collectionsBrick: any = [];
  // condicionando visibilidad de opciones de edicion de orden 
  flagEditOrder: any = false
  lastnameColaborator: any = ''
  nameColaborator: any = ''

  // multiples numeros de facturas
  codeBillArray: any = []
  flagBill = false
  codeBill: any
  flagBtnBill: boolean = false;

  constructor(
    public api: ApiService,
    public sap: SapService,
    public navParams: NavParams,
    public modalController: ModalController,
    private zone: NgZone,
    public auth: AuthService,
    public loadingCtrl: LoadingController,
    private router: Router,
    private http: HttpClient,
    private ordersService: OrdersService,
    private SwalService: SwalService,
    private functionsService: FunctionsService
  ) {
    this.order = navParams.get('order');
    console.log("init component this.order = ", this.order)
    this.options.unshift();
    // recuperando informacion de los Colaboradores (delivery)
    this.api.getAllDocuments(`delivery`).then(data => {
      if (data) {
        data.forEach(element => {
          if (element['countryCode'] == this.auth.countryCode && element['active'] == true) {

            let arr = []
            // //console.log(element)
            arr['id'] = element.$key ? element.$key : ''
            arr['lastname'] = element.lastname ? element.lastname : ''
            arr['name'] = element.name ? element.name : ''
            arr['phonenumber'] = element.phonenumber ? element.phonenumber : ''

            this.options.push(arr);
          }

        });
      }
    });
  }

  @ViewChild('numFactura', { static: false }) numFactura: IonInput;

  onlyNumbers(event: any) {
    const charCode = (event.query) ? event.query : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    } else {
      return false;
    }
  }

  async guardarFactura(order: any) {
    //console.log("guardarFactura order = ", order)
    const resp = await this.ordersService.updateOrder(order.$key, this.numFactura.value.toLocaleString());
    //console.log("resp = ", resp)
    if (resp == false) {
    } else {
      //console.log("else response", resp)
      this.order = await this.ordersService.getOrderById(order.number);
      this.numFactura.value = '';
      this.modalController.dismiss();
    }
  }

  public closeModal = async () => {
    await this.modalController.dismiss();
  }

  async saveNumbersBill(order) {
    // console.log("init saveNumbersBill()")
    // console.log("codeBillArray = ", this.codeBillArray)
    this.flagBtnBill = true

    this.SwalService.fire({
      title: 'Estás seguro Guardar?',
      text: "¡No podrás revertir esto!",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, estoy seguro'
    }).then(async (result) => {
      if (result.value) {
        await this.api.getDocument('orders', order.$key).then(async data => {
          // console.log("data = ", data)
          if (data) {
            // console.log("data['idBill'] = ", data['idBill'])
            let idBillOld = data['idBill'] != undefined ? data['idBill'] : []
            // console.log("idBillOld = ", idBillOld)
            this.codeBillArray = [...new Set(this.codeBillArray.concat(idBillOld))]
            // console.log("this.codeBillArray = ", this.codeBillArray)

            this.api.updateDocument('orders', order.$key, {
              idBill: this.codeBillArray
            }).then(data => {
              this.mensajeAlerta('success', 'Números de Facturas. Guardados con exito.');
            })
          }
        });

      }
    })
  }

  mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
    Swal.fire({
      position: 'top-end',
      icon: icono,
      title: mensaje,
      showConfirmButton: false,
      timer: 3500,
      toast: true
    });
  }

  onChange(item) {
    this.phone = item.phonenumber
    this.lastnameColaborator = item.lastname
    this.nameColaborator = item.name
  }

  changeCalled(type) {
    if (this.phone) {
      //        // console.log(this.order = ", this.order)
      let nameFull = '' + this.order.account.lastname + ' ' + this.order.account.name
      openInNewTab(type, this.phone, this.order.address.lat, this.order.address.lng, nameFull, this.order.address.location, this.order.address.houseDetail, this.order.address.landMark, this.order.payment.type, this.nameColaborator, this.lastnameColaborator)
    } else {
      this.SwalService.fire(
        'Seleccione Datos de Delivery',
        'Registre en Menu -> Delivery. Los datos #CodigoPais + Telefono',
        'warning'
      )
    }
  }

  async ngOnInit() {
    let response = await this.getInfoOrder()
    // console.log("getInfoOrder() >> response = ", response)
  }

  ionViewWillEnter() {
    this.forceUpdate();
  }

  forceUpdate() {
    this.zone.run(() => {
      // //console.log('force update the screen');
    });
  }

  async seeBrick(brick) {

    const modal = await this.modalController.create({
      component: BrickPreviewComponent,
      cssClass: 'addModal',
      componentProps: {
        brick: brick.brick
      }
    });
    return await modal.present();
  }

  async loader() {
    return await this.loadingCtrl.create({
      spinner: 'lines-small',
      animated: true,
      mode: 'ios',
      translucent: false,
      cssClass: 'custom-class custom-loading',
    });
  }

  captureSplit(split) {
    this.loader().then(loader => {
      loader.present().then(() => {

        this.SwalService.fire({
          title: 'Esta seguro que deseas Aceptar la transferencia?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
          if (result.value) {
            let getData = `orderNo=split-${split.$key}&amount=${split.amount}&type=1`;
            let url;

            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

            this.http.get(url)
              .subscribe((response) => {
                if (response['TransactionModificationResult']['ResponseCode'] == 1) {

                  this.api.updateDocument('splits', split.$key, {
                    'transaction_status': 'captured',
                  }).then(data => {
                    split.transaction_status = 'captured';
                    this.SwalService.fire(
                      'Transacción Capturada',
                      'Captura de fondos realizar con exito',
                      'success'
                    )
                  })

                  this.SwalService.fire(
                    'Transacción Capturada',
                    'Captura de fondos realizar con exito',
                    'success'
                  )
                } else {
                  this.SwalService.fire(
                    'Error!',
                    'No se pudo completar la captura de la transacción, comunicate con el equipo de barlleno',
                    'error'
                  )
                }
                loader.dismiss();
              }, err => {
                loader.dismiss();
              });
          } else {
            loader.dismiss();
          }
        });
      })
    })
  }

  capture(order) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
      })
    };

    this.loader().then(loader => {
      loader.present().then(() => {
        this.SwalService.fire({
          title: 'Esta seguro que deseas Aceptar la transferencia?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {

          if (result.value) {
            // let getData = `orderNo=${order.number}&amount=${order.total}&type=1`;
            let getData = `orderNo=${order.number}&split-${order.$key}&amount=${order.total}&type=1`;
            let transaction_id = order.transaction_id;
            let url;
            if (this.auth.countryCode == 'SV') {
              url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

            } else if (this.auth.countryCode == 'GT') {
              url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
            } else if (this.auth.countryCode == 'CO') {
              url = ((`https://production.wompi.co/v1/transactions/${transaction_id}`));
            }

            if (this.auth.countryCode !== 'CO') {
              this.http.get(url)
                .subscribe((response) => {
                  if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                    this.api.updateDocument('orders', order.$key, {
                      'transaction_status': 'captured',
                      'status': 'making'
                    }).then(data => {
                      order.transaction_status = 'captured';
                      this.SwalService.fire(
                        'Transacción Capturada',
                        'Captura de fondos realizar con exito',
                        'success'
                      )
                    })
                  } else {
                    this.SwalService.fire(
                      'Error!',
                      'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
                      'error'
                    )
                  }
                  loader.dismiss();
                  this.forceUpdate();
                  this.router.navigate(['/monitor'])
                }, err => {
                  loader.dismiss();
                });
            }


            else {
              this.http.get(url, httpOptions)
                .subscribe((response) => {
                  if (response['data']['status'] == 'APPROVED') {
                    this.api.updateDocument('orders', order.$key, {
                      'transaction_status': 'captured',
                      'status': 'making'
                    }).then(data => {
                      order.transaction_status = 'captured';
                      this.SwalService.fire(
                        'Transacción Capturada',
                        'Captura de fondos realizar con exito',
                        'success'
                      )
                    })
                  } else {
                    this.SwalService.fire(
                      'Error!',
                      'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
                      'error'
                    )
                  }
                  loader.dismiss();
                }, err => {
                  loader.dismiss();
                });
            }

          } else {
            loader.dismiss();
          }
        });
      })
    })
  }

  revertSplit(split) {
    this.loader().then(loader => {
      loader.present().then(() => {
        this.SwalService.fire({
          title: 'Esta seguro que deseas Revertir la transferencia?',
          text: "¡No podrás revertir esto!",
          icon: 'error',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
          if (result.value) {
            let getData = `orderNo=split-${split.$key}&amount=${split.amount}&type=3`;
            this.http.get(`https://payments.barlleno.app/transactionModification.php?${getData}`)
              .subscribe((response) => {
                if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                  this.api.updateDocument('splits', split.$key, {
                    'transaction_status': 'Reverted',
                  }).then(data => {
                    split.transaction_status = 'Reverted';
                    this.SwalService.fire(
                      'Transacción Capturada',
                      'Captura de fondos realizar con exito',
                      'success'
                    )
                  })
                  this.SwalService.fire(
                    'Transacción Revertida',
                    'Reversión de fondos realizar con exito',
                    'success'
                  )
                } else {
                  this.SwalService.fire(
                    'Error!',
                    'No se pudo revertir la transacción, comunicate con el equipo de barlleno',
                    'error'
                  )
                }
                loader.dismiss();
              }, err => {
                loader.dismiss();
              });
          }
          else {
            loader.dismiss();
          }
        })
      })
    })
  }

  captureOrder() {
    this.loader().then(loader => {
      loader.present().then(() => {

        this.SwalService.fire({
          title: 'Esta seguro que deseas Aceptar la transferencia?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
          if (result.value) {
            let getData = `orderNo=${this.order.number}&amount=${this.order.total}&type=1`;
            let url;

            url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

            this.http.get(url)
              .subscribe((response) => {
                if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                  this.api.updateDocument('orders', this.order.$key, {
                    'transaction_status': 'captured',
                    'status': 'making'
                  }).then(data => {
                    this.order.transaction_status = 'captured';
                    this.SwalService.fire(
                      'Transacción Capturada',
                      'Captura de fondos realizar con exito',
                      'success'
                    )
                  })
                } else {
                  this.SwalService.fire(
                    'Error!',
                    'No se pudo completar la captura de la transacción, comunicate con el equipo de barlleno',
                    'error'
                  )
                }
                loader.dismiss();
              }, err => {
                loader.dismiss();
              });
          } else {
            loader.dismiss();
          }
        });
      })
    })
  }

  revertOrder() {
    this.loader().then(loader => {
      loader.present().then(() => {
        this.SwalService.fire({
          title: 'Esta seguro que deseas Revertir la transferencia?',
          text: "¡No podrás revertir esto!",
          icon: 'error',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, estoy seguro'
        }).then((result) => {
          if (result.value) {
            let getData = `orderNo=${this.order.number}&amount=${this.order.total}&type=3`;
            this.http.get(`https://payments.barlleno.app/transactionModification.php?${getData}`)
              .subscribe((response) => {
                if (response['TransactionModificationResult']['ResponseCode'] == 1) {
                  this.api.updateDocument('orders', this.order.$key, {
                    'transaction_status': 'Reverted',
                    'status': 'canceled'

                  }).then(data => {
                    this.order.transaction_status = 'Reverted';
                    this.SwalService.fire(
                      'Transacción Revertida',
                      'Reversión de fondos realizar con exito',
                      'success'
                    )
                  })
                } else {
                  this.SwalService.fire(
                    'Error!',
                    'No se pudo revertir la transacción, comunicate con el equipo de barlleno',
                    'error'
                  )
                }
                loader.dismiss();
              }, err => {
                loader.dismiss();
              });
          }
          else {
            loader.dismiss();
          }
        })
      })
    })
  }

  sendEmailRequest() {
    //console.log("ingreso sendEmailRequest()")
    this.loader().then(loader => {
      loader.present().then(async () => {
        //console.log(`https://mails.barlleno.app/mails_inventoryTest.php?mode=notification_zendesk_order_fail&email=${window.btoa(JSON.stringify(this.auth.email))}&countryCode=${window.btoa(JSON.stringify(this.auth.countryCode))}&created_at=${window.btoa(JSON.stringify(new Date()))}&role=${this.auth.role}&order=${window.btoa(JSON.stringify(this.order))}`)
        await this.http.get(`https://mails.barlleno.app/mails_inventoryTest.php?mode=notification_zendesk_order_fail&email=${window.btoa(JSON.stringify(this.auth.email))}&countryCode=${window.btoa(JSON.stringify(this.auth.countryCode))}&created_at=${window.btoa(JSON.stringify(new Date()))}&role=${this.auth.role}&order=${window.btoa(JSON.stringify(this.order))}`)
          .subscribe((response) => {
            loader.dismiss();
            this.SwalService.fire('¡Listo!', 'Se ha enviado con éxito solicitud de Asistencia Técnica.', 'success');
          })
      })
    })
  }

  orderEmail(id: string) {
    this.loader().then(loader => {
      loader.present().then(() => {
        this.functionsService.sendEmail(id)
          .then(() => {
            loader.dismiss();
            this.SwalService.fire(
              'Correo envíado',
              'Se envió con con éxito',
              'success'
            )
          })
          .catch((err) => {
            loader.dismiss();
            this.SwalService.fire(
              'Error',
              'No se pudo enviar el correo',
              'error'
            )
          })

      })
    })
  }

  // componente para intercambiar productos en monitor barlleno
  async changeBrick(order, brick, isModal = true) {
    // console.log("init changeBrick => order = ", order, "brick = ", brick)
    // logs de intercambio
    let flagFist = false
    // console.log("!order.bricks_exchange", !order.bricks_exchange)
    if (!order.bricks_exchange && !order.bricks_original) {
      order['bricks_exchange'] = [brick] // .push(brick)
      // guardando primera instantanea del brick en base bricks_original
      order['bricks_original'] = [brick]
      flagFist = true
    }
    if (!order.bricks_exchange) {
      order['bricks_exchange'] = [brick] // .push(brick)
    } else {
      if (!order['bricks_exchange'].find(element => element.$key == brick.$key)) {
        order['bricks_exchange'].push(brick)
      }
    }
    // console.log("order create bricks_exchange = ", order)

    if (flagFist) {
      this.api.updateDocument('orders', order.$key, {
        bricks_exchange: order['bricks_exchange'],
        bricks_original: order['bricks_original']
      }).then(data => {
        // console.log("intercambio init - bricks_original SI")
      })
    } else {
      this.api.updateDocument('orders', order.$key, {
        bricks_exchange: order['bricks_exchange']
      }).then(data => {
        // console.log("intercambio init - bricks_original NO")
      })
    }

    if (isModal == false) {
      this.deleteChangeBrick(brick, order.number, brick.brick)
    }

    if (isModal) {
      const modal = await this.modalController.create({
        component: BrickChangeComponent,
        cssClass: 'addModal',
        componentProps: {
          brick_delete: brick.brick,
          number_order: order.number
        }
      });
      modal.onDidDismiss().then(async data => {
        // window.location.reload();
        // console.log("ocultando modal BrickChangeComponent")
        await this.api.getDocument('orders', order.$key).then(async data => {
          this.order = data;
          let response = await this.getInfoOrder()
          // console.log("getInfoOrder() >> response = ", response)
        })
      })

      return await modal.present();
    }
  }
  // eliminando brick de la orden funcion aux para respaldar los datos originales
  deleteChangeBrick(brick, number_order, brick_delete) {
    // console.log("init deleteChangeBrick", "number_order = ", number_order, "brick.presentations_data =", brick.presentations_data)

    let bricksUpdate = []
    if (number_order) {
      this.loader().then(loader => {
        loader.present().then(() => {
          this.api.getRef('orders').ref
            .where('number', '==', number_order)
            .get()
            .then(async snapshots => {
              if (snapshots.size > 0) {
                const { docs } = snapshots
                let { bricks } = docs[0].data();
                let keyOrder = docs[0].id
                // procedo a eliminar el brick que estoy reemplazando
                for (let index = 0; index < bricks.length; index++) {
                  let element = bricks[index];
                  if (element.brick_key == brick_delete.$key) {
                    delete bricks[index]
                    // break
                  } else {
                    bricksUpdate.push(bricks[index])
                  }
                }
                // console.log("bricks in Orders = ", bricks)

                await this.api.updateDocument('orders', keyOrder, {
                  bricks: bricksUpdate
                }).then(async data => {
                  // console.log("orden actualizada con nuevos productos")
                  // console.log("ocultando modal BrickChangeComponent")
                  await this.api.getDocument('orders', keyOrder).then(async data => {
                    this.order = data;
                    let response = await this.getInfoOrder()
                    // console.log("getInfoOrder() >> response = ", response)
                  })
                  loader.dismiss();
                  // window.location.reload();

                })
              }
            })
        })
      })
    }
    // loader.dismiss();
    // window.location.reload();

  }

  getInfoOrder() {
    return new Promise((resolve, reject) => {
      if (typeof this.order.account === 'string') {
        // console.log('this.order.account >> Variable is a string');
        this.api.getDocument('accounts', this.order.account).then(data => {
          this.order.account = data;
        })
      }
      if (this.order.hasOwnProperty('event_brick_table') && typeof this.order.event_brick_table === 'string') {
        // console.log('this.order.account >> Variable is a string');
        this.api.getDocument('bricks', this.order.event_brick_table).then(data => {
          this.order.event_brick_table = data;
        })
      } else if (this.order.hasOwnProperty('event_category_key')) {
        this.api.getDocument('bricks', this.order.event_category_key).then(data => {
          this.order.event_brick_table = data;
        })
      }
      if (typeof this.order.added_date === 'object') {
        // console.log("this.order.added_date >> object",)
        // this.order.added_date = this.order.added_date.toDate()
      }
      if (this.order.splits) {
        this.order.splits.forEach(element => {

          this.amountSplit += element.amount
          // //console.log(element.amount)

          this.api.getDocument('accounts', element.friend).then(data => {
            element.friend = data;
          })
        });
        // //console.log("------------> this order");
        // //console.log(this.order)
        // //console.log("this.amountSplit = ", this.amountSplit)
      }

      // //console.log("this.order en el modal",this.order)
      let countPartnerEstrellas = 0
      let checkClubBarlleno = 0
      let checkBarlleno = 0
      this.order.supplier_partner = []
      this.order['bricks'].forEach(element => {
        //console.log("checkBarlleno", checkBarlleno, "el brick en la ORDEN = ", element)
        // //console.log("isPartner", element['brick'].partner, "element['brick'].partner", element['brick'].partner)
        // //console.log("element.brick_key", element.brick_key, "element en bricks", element)
        if (element['brick'].hasOwnProperty('partner') && element['brick'].partner == true && element['type'] == 'club') {
          // //console.log("element['quantity']", element['quantity'], "element['price']", element['price'])
          countPartnerEstrellas = countPartnerEstrellas + (Number(element['quantity']) * Number(element['price']))

          // //console.log("countPartnerEstrellas", countPartnerEstrellas)
          if (element['brick'].supplier_partner != undefined && element['brick'].supplier_partner != '') this.order.supplier_partner.push(element['brick'].supplier_partner)
          // //console.log("order ===>> ", this.order)
        }
        // iniciando el check de los puntos barlleno de cada uno de los bricks
        if (element.type == 'club' && !element['brick'].hasOwnProperty('partner')) {
          //console.log("element.price = ", element.price, "element.quantity = ", element.quantity, "element.subtotal_points = ", element.subtotal_points)
          checkClubBarlleno = checkClubBarlleno + (Number(element.price) * Number(element.quantity))
        }
        if (element.type == 'brick') {
          checkBarlleno = checkBarlleno + (Number(element.price) * Number(element.quantity))
        }
        // agregando los detalles de las productos asosciados
        if (element['brick'].hasOwnProperty('referenceKits')) {
          this.countProducts = (element.brick.referenceKits.length * element.quantity) + this.countProducts
          // console.log("element['brick'].hasOwnProperty('referenceKits') = ", element.brick.referenceKits)
          for (let index = 0; index < element.brick.referenceKits.length; index++) {
            const item = element.brick.referenceKits[index];
            this.api.getDocument('bricks', item.reference).then(data => {
              item['Info'] = data;
              this.collectionsBrick.push({ name: item['Info']['name'] })
            })
          }
        }
      });
      //console.log("this.collectionsBrick = ",this.collectionsBrick)
      // calculo de estrellas
      // //console.log("countPartnerEstrellas", countPartnerEstrellas, "this.order.total_points_partner", this.order.total_points_partner)
      if (countPartnerEstrellas > 0) this.order.total_points_partner == countPartnerEstrellas ? this.isTotalPartnerFail = false : this.isTotalPartnerFail = true
      // this.order.partner = true
      // this.order.total_points_partner = countPartnerEstrellas

      // calculo de puntos club barlleno
      if (checkClubBarlleno > 0) checkClubBarlleno == this.order.total_points ? this.isTotalClubFail = false : this.isTotalClubFail = true

      // calculo de barlleno
      // //console.log("checkBarlleno", checkBarlleno.toFixed(2), "this.order.total", Number(this.order.total) , Number(this.order.supplier_discount_total) , Number(this.order.second_round_discount) , Number(this.order.bin_cant_discount) , Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0) )
      // //console.log("----------->>>>>>>", checkBarlleno , (Number(this.order.total) + Number(this.order.supplier_discount_total)) )
      // //console.log("this.order", this.order, "this.amountSplit = ", this.amountSplit , typeof this.amountSplit)
      // //console.log(" ===> ", (Number(this.order.total > 0 ? this.order.total : 0) + Number(this.order.supplier_discount_total > 0 ? this.order.supplier_discount_total : 0) + Number(this.order.second_round_discount > 0 ? this.order.second_round_discount : 0) + Number(this.order.bin_cant_discount > 0 ? this.order.bin_cant_discount : 0) + Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0) - Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0) - Number(this.order.tip > 0 ? this.order.tip : 0) - Number(this.order.gift_price > 0 ? this.order.gift_price : 0)- Number(this.amountSplit > 0 ? this.amountSplit : 0) ).toFixed(2))
      if (checkBarlleno > 0) checkBarlleno.toFixed(2) == (Number(this.order.total > 0 ? this.order.total : 0) + Number(this.order.supplier_discount_total > 0 ? this.order.supplier_discount_total : 0) - Number(this.order.supplier_discount_not_applied > 0 ? this.order.supplier_discount_not_applied : 0) + Number(this.order.second_round_discount > 0 ? this.order.second_round_discount : 0) + Number(this.order.bin_cant_discount > 0 ? this.order.bin_cant_discount : 0) + Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0) - Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0) - Number(this.order.tip > 0 ? this.order.tip : 0) - Number(this.order.gift_price > 0 ? this.order.gift_price : 0) + Number(this.amountSplit > 0 ? this.amountSplit : 0)).toFixed(2) ? this.isTotalBarllenoFail = false : this.isTotalBarllenoFail = true
      this.order['supplier_discount_total'] > 0 ? this.order['supplier_discount_total'] : this.order['supplier_discount_total'] = 0
      this.order['supplier_discount_not_applied'] > 0 ? this.order['supplier_discount_not_applied'] : this.order['supplier_discount_not_applied'] = 0
      this.order['second_round_discount'] > 0 ? this.order['second_round_discount'] : this.order['second_round_discount'] = 0
      this.order['all_store_discount'] > 0 ? this.order['all_store_discount'] : this.order['all_store_discount'] = 0

      // fin de ajuste de orden para mostrar detalles de facturacion TOTALES 

      if (this.order.scheduled) {
        this.order.schedule_time = this.order.schedule_time.toDate();
        this.order.schedule_date = this.order.schedule_date.toDate();
      }
      resolve('success')
    });
  }

  // addBrickOrder agregando nuevos productos a la orden actual
  async addBrickOrder(order, isModal = true) {
    if (isModal) {
      // console.log("order antes de contruir el modal = ", order)
      const modal = await this.modalController.create({
        component: BrickChangeComponent,
        cssClass: 'addModal',
        componentProps: {
          number_order: order.number,
          order: order
        }
      });

      modal.onDidDismiss().then(async data => {
        //     window.location.reload();
        // console.log("ocultando modal BrickChangeComponent")
        await this.api.getDocument('orders', order.$key).then(async data => {
          this.order = data;
          let response = await this.getInfoOrder()
          // console.log("getInfoOrder() >> response = ", response)
        })
      })
      return await modal.present();
    }
  }

  // recalculando totales de la orden
  updateTotalOrder(order) {
    // console.log("order = ", order.total, order)
    // return 
    this.loader().then(loader => {
      loader.present().then(() => {
        if (order.payment.type == 'cash') {
          // console.log("order = ", order)
          Number(order.tip) > 0 ? order.tip = Number(order.tip) : order.tip = 0
          Number(order.total_delivery) > 0 ? order.total_delivery = Number(order.total_delivery) : order.total_delivery = 0

          let totalSuma = 0
          for (let i = 0; i < order.bricks.length; i++) {
            totalSuma = totalSuma + order.bricks[i].subtotal
          }
          let total = totalSuma > 0 ? order.total_cart = totalSuma : order.total_cart = 0
          total = total + order.tip + order.total_delivery + order.gift_price
          order.change = order.change > total ? order.change : total
          // console.log("total = ", total)
          this.api.updateDocument('orders', order.$key, {
            'total': total,
            'change': order.change,
            'brick_change': true
          }).then(async data => {
            await this.api.getDocument('orders', order.$key).then(async data => {
              this.order = data;
              this.SwalService.fire(
                'Orden Actualizada',
                'Totales actualizados',
                'success'
              )
              await this.notificationsOff()
            })
            loader.dismiss();
            // window.location.reload();
          })
        } else if (order.payment.type == 'card') {
          if (order.total_payment_card) {
            order.total = order.total_payment_card
          }
          order['total_payment_card'] = order.total

          // console.log("order = ", order)
          Number(order.tip) > 0 ? order.tip = Number(order.tip) : order.tip = 0
          Number(order.total_delivery) > 0 ? order.total_delivery = Number(order.total_delivery) : order.total_delivery = 0

          let totalSuma = 0
          for (let i = 0; i < order.bricks.length; i++) {
            totalSuma = totalSuma + order.bricks[i].subtotal
          }
          // console.log("valor actual de cart -> totalSuma = ", totalSuma)
          order['total_payment_cash'] = 0
          if (order.total_payment_cash < 0) {
            this.SwalService.fire(
              'BARLLENO',
              `ORDEN ${order.number} Consumo en Orden Tienen que ser mayor a  ${order.total}`,
              'warning'
            )
            loader.dismiss();
            window.location.reload();
            return
          }

          let total = totalSuma > 0 ? order.total_cart = totalSuma : order.total_cart = 0
          total = total + order.tip + order.total_delivery + order.gift_price
          order.change = 0
          // console.log("order = ", order, "total_payment_cash = ", order.total_payment_cash, "total_payment_card = ", order.total_payment_card)
          order.total_payment_cash = total - order.total_payment_card  // lo pagado con tarjeta - nuevo total
          // console.log("total = ", total)
          this.api.updateDocument('orders', order.$key, {
            'total': total,
            'change': order.change,
            'brick_change': true,
            'total_payment_card': order.total_payment_card,
            'total_payment_cash': order.total_payment_cash
          }).then(async data => {

            await this.api.getDocument('orders', order.$key).then(async data => {
              this.order = data;
              this.SwalService.fire(
                'Orden Actualizada',
                'Totales actualizados',
                'success'
              )
              await this.notificationsOff()

            })
            loader.dismiss();
            // window.location.reload();
          })
        }
      })
    })
  }

  async notificationsOff() {
    // console.log("notificationsOff()")
    // this.isTotalClubFail = false
    // this.isTotalPartnerFail = false
    // this.isTotalBarllenoFail = false
    let response = await this.getInfoOrder()
    // console.log("getInfoOrder() >> response = ", response)
  }

  booleanEditOrderProducts() {
    // console.log("booleanEditOrderProducts ",)
    this.flagEditOrder = true
  }

  // manejo de multiples numeros de facturas
  addCodeBill() {
    // console.log("init addCodeBill()")
    this.flagBill = true
    this.codeBillArray.push(this.codeBill)
    // console.log("array codeBillArray = ", this.codeBillArray)
    this.codeBill = ''
    this.flagBtnBill = false
  }

  changeStatusService(order, status, gift = false) {
    return new Promise(async (resolve, reject) => {
      let respService = await this.functionsService.changeStatus(order, status)
      console.log("respService = ", respService)
      resolve('success')
    });
  }

  captureService(order) {
    return new Promise(async (resolve, reject) => {
      let respCaptureService = await this.functionsService.capture(order)
      console.log("respCaptureService = ", respCaptureService)
      resolve('success')
    });
  }

  captureCashService(order) {
    return new Promise(async (resolve, reject) => {
      let respCaptureCashService = await this.functionsService.captureCash(order)
      console.log("respCaptureCashService = ", respCaptureCashService)
      resolve('success')
    });
  }

  // enviando correo con e-ticket


  async changeTickets(order, brick_name = 'E-Ticket') {
    console.log("order = ", order)
    this.SwalService.fire({
      title: `Esta seguro que desea Enviar CÓDIGO QR?`,
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, estoy seguro'
    }).then(async (result) => {
      if (result.value) {
        if (order.hasOwnProperty('number_original')) {
          console.log("es una orden clon")
        } else if (!order.hasOwnProperty('number_original')) {
          console.log("es una orden original")

          // evaluando si la original contiene viernes y sabado // multiples fechas
          let aumentador: any = 0
          // evaluando multiples bricks tipo = tickets en orden
          const busqueda = order['bricks'].reduce((acc, brick) => {
            // console.log("persona.presentation.indexOf('TICKET') = ", brick.presentation.indexOf('TICKET'))
            if (brick.presentation.indexOf('TICKET')) {
              acc[brick.presentation] = ++acc[brick.presentation] || 0;
              return acc;
            } else return 0
          }, {});

          const duplicados = order['bricks'].filter((brick) => {
            return busqueda[brick.presentation];
          });
          if (duplicados.length > 0) {
            aumentador = 1
          }
          // fin evaluacion del contenido de la orden
          if (!(aumentador > 0)) {
            // >>> enviando correo original
            let respEmail = await this.initSendEmail('number', order.number, order.account)
          } else {
            await this.api.updateDocument('orders', order.$key, {
              splitETicketSendEmail: true,
            })
          }
          if (order.hasOwnProperty('splitETicketMulti') && order.splitETicketMulti == true) {
            // estoy con una orden que SI fue dividida
            console.log("orden SI tiene hijas")
            // >>> enviando correos a clones
            let respEmail = await this.initSendEmail('number_original', order.number, order.account)

          } else if (order.hasOwnProperty('splitETicketMulti') && order.splitETicketMulti == false) {
            // estoy con una orden que NO fue dividida
            console.log("orden NO tiene hijas")
            // >>> no enviar correo
          }
        }
      }
    })
  }

  initSendEmail(clave, valor, account) {
    return new Promise(async (resolve, reject) => {
      await this.api.getRef('orders').ref
        .where(clave, "==", valor)
        .get()
        .then(async snapshots => {
          if (snapshots.size > 0) {
            for (let i = 0; i < snapshots.size; i++) {
              let doc = snapshots.docs[i]
              let orderInfo = doc.data();
              orderInfo.$key = doc.id;
              console.log("orderInfo number = ", orderInfo.number)
              let respSAP = await this.sap.getPostsTicketevent(
                this.auth.token,
                orderInfo.number,
                orderInfo.number,
                orderInfo.$key,
                null,                
              )
              console.log("respWebServiceEmail = ", respSAP)
              this.SwalService.fire(
                'Correo envíado',
                `Respuesta de mensajeria es: ${respSAP['message']}`,
                'success'
              )
            }
            resolve('success')
          } else {
            resolve('success')
          }
        })
    });
  }
}


function openInNewTab(type, phone, lat, lng, name = '', location = '', houseDetail = '', landMark = '', paymentType = '', nameColaborator = '', lastnameColaborator = '') {
  landMark = replaceAll(landMark, "#", 'n').trim()
  location = replaceAll(location, '#', 'n').trim()
  houseDetail = replaceAll(houseDetail, '#', 'n').trim()
  nameColaborator = nameColaborator.trim()
  name = name.trim()
  // apoyo en https://www.wati.io/free-whatsapp-link-generator/ generar el contenido dinamico
  if (type == 'google') {
    // google
    // var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola,%20Esta%20es%20la%20localizacion%20del%20cliente%20${name}%20%20https://www.google.com.ar/maps/place/${lat},${lng}`, '_blank');
    var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola%20*${nameColaborator}*%2C%20tienes%20un%20pedido%20asignado%20para%20*${name}*%0A%0ALa%20direcci%C3%B3n%20es%20*${location}*.%20La%20casa%2FPiso%20tiene%20numero.%20*${houseDetail}*%20%0A%0ALa%20referencia%20*${landMark}*%20%0A%0AEl%20pago%20fue%20en%20*${paymentType}*%20y%20las%20coordenadas%20son%20https://www.google.com.ar/maps/place/${lat},${lng}`, '_blank');
    win.focus();
  } else {
    // waze
    // var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola,%20Esta%20es%20la%20localizacion%20del%20cliente%20${name}%20%20https://waze.com/ul?ll=${lat},${lng}&z=10`, '_blank');
    // var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola%20*${nameColaborator}*,%20tienes%20un%20pedido%20asignado%20para%20*${name}*%20la%20dirección%20es%20*${location}*.%20La%20casa/Piso%20tiene%20numero.%20*${houseDetail}*%20la%20referencia%20*${landMark}*%20,%20el%20pago%20fue%20en%20*${paymentType}*%20y%20las%20coordenadas%20son%20https://waze.com/ul?ll=${lat},${lng}&z=10`, '_blank');
    var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola%20*${nameColaborator}*%2C%20tienes%20un%20pedido%20asignado%20para%20*${name}*%0A%0ALa%20direcci%C3%B3n%20es%20*${location}*.%20La%20casa%2FPiso%20tiene%20numero.%20*${houseDetail}*%20%0A%0ALa%20referencia%20*${landMark}*%20%0A%0AEl%20pago%20fue%20en%20*${paymentType}*%20y%20las%20coordenadas%20son%20https://waze.com/ul?ll=${lat},${lng}&z=10`, '_blank');
    win.focus();
  }
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}
