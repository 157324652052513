import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { NavParams, ModalController, LoadingController, IonInput } from '@ionic/angular';
import { BrickPreviewComponent } from '../brick-preview/brick-preview.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/services/database/orders.service';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
	selector: 'app-pre-order',
	templateUrl: './pre-order.component.html',
	styleUrls: ['./pre-order.component.scss'],
})
export class PreOrderComponent implements OnInit {
	phone: any
	options = [];
	items: any
	order: any;
	amountSplit: any = 0
	isTotalClubFail: any = false
	isTotalPartnerFail: any = false;
	isTotalBarllenoFail: any = false;
	countProducts: any = 0
	referenceKits: any = []
	collectionsBrick: any = [];

	constructor(
		public api: ApiService,
		public navParams: NavParams,
		public modalController: ModalController,
		private zone: NgZone,
		public auth: AuthService,
		public loadingCtrl: LoadingController,
		private router: Router,
		private http: HttpClient,
		private ordersService: OrdersService,
		private SwalService: SwalService
	) {
		console.log("Hola")
		this.order = navParams.get('order');

		// console.log("this.order en el modal",this.order)
		let countPartnerEstrellas = 0
		let checkClubBarlleno = 0
		let checkBarlleno = 0
		this.order.supplier_partner = []
		// no aplica en solicitud de productos
		// this.order['bricks'].forEach(element => {
		// // console.log(("checkBarlleno", checkBarlleno, "el brick en la ORDEN = ", element)
		// 	// console.log("isPartner", element['brick'].partner, "element['brick'].partner", element['brick'].partner)
		// 	// console.log("element.brick_key", element.brick_key, "element en bricks", element)
		// 	if (element['brick'].hasOwnProperty('partner') && element['brick'].partner == true && element['type'] == 'club') {
		// 		// console.log("element['quantity']", element['quantity'], "element['price']", element['price'])
		// 		countPartnerEstrellas = countPartnerEstrellas + (Number(element['quantity']) * Number(element['price']))

		// 		// console.log("countPartnerEstrellas", countPartnerEstrellas)
		// 		if (element['brick'].supplier_partner != undefined && element['brick'].supplier_partner != '') this.order.supplier_partner.push(element['brick'].supplier_partner)
		// 		// console.log("order ===>> ", this.order)
		// 	}
		// 	// iniciando el check de los puntos barlleno de cada uno de los bricks
		// 	if (element.type == 'club' && !element['brick'].hasOwnProperty('partner')) {
		// 	// console.log(("element.price = ", element.price, "element.quantity = ", element.quantity, "element.subtotal_points = ", element.subtotal_points)
		// 		checkClubBarlleno = checkClubBarlleno + (Number(element.price) * Number(element.quantity))
		// 	}
		// 	if (element.type == 'brick') {
		// 		checkBarlleno = checkBarlleno + (Number(element.price) * Number(element.quantity))
		// 	}
		// 	// agregando los detalles de las productos asosciados
		// 	if (element['brick'].hasOwnProperty('referenceKits')) {
		// 		this.countProducts = (element.brick.referenceKits.length * element.quantity) + this.countProducts
		// 		// console.log("element['brick'].hasOwnProperty('referenceKits') = ", element.brick.referenceKits)
		// 		for (let index = 0; index < element.brick.referenceKits.length; index++) {
		// 			const item = element.brick.referenceKits[index];
		// 			this.api.getDocument('bricks', item.reference).then(data => {
		// 				item['Info'] = data;
		// 				this.collectionsBrick.push({ name: item['Info']['name'] })
		// 			})
		// 		}
		// 	}
		// });
		// console.log("this.collectionsBrick = ", this.collectionsBrick)
		// calculo de estrellas
		// console.log("countPartnerEstrellas", countPartnerEstrellas, "this.order.total_points_partner", this.order.total_points_partner)
		if (countPartnerEstrellas > 0) this.order.total_points_partner == countPartnerEstrellas ? this.isTotalPartnerFail = false : this.isTotalPartnerFail = true
		// this.order.partner = true
		// this.order.total_points_partner = countPartnerEstrellas

		// calculo de puntos club barlleno
		if (checkClubBarlleno > 0) checkClubBarlleno == this.order.total_points ? this.isTotalClubFail = false : this.isTotalClubFail = true

		// calculo de barlleno
		// console.log("checkBarlleno", checkBarlleno.toFixed(2), "this.order.total", Number(this.order.total) , Number(this.order.supplier_discount_total) , Number(this.order.second_round_discount) , Number(this.order.bin_cant_discount) , Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0) )
		// console.log("----------->>>>>>>", checkBarlleno , (Number(this.order.total) + Number(this.order.supplier_discount_total)) )
		// console.log("this.order", this.order, "this.amountSplit = ", this.amountSplit , typeof this.amountSplit)
		// console.log(" ===> ", (Number(this.order.total > 0 ? this.order.total : 0) + Number(this.order.supplier_discount_total > 0 ? this.order.supplier_discount_total : 0) + Number(this.order.second_round_discount > 0 ? this.order.second_round_discount : 0) + Number(this.order.bin_cant_discount > 0 ? this.order.bin_cant_discount : 0) + Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0) - Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0) - Number(this.order.tip > 0 ? this.order.tip : 0) - Number(this.order.gift_price > 0 ? this.order.gift_price : 0)- Number(this.amountSplit > 0 ? this.amountSplit : 0) ).toFixed(2))
		if (checkBarlleno > 0) checkBarlleno.toFixed(2) == (Number(this.order.total > 0 ? this.order.total : 0) + Number(this.order.supplier_discount_total > 0 ? this.order.supplier_discount_total : 0) + Number(this.order.second_round_discount > 0 ? this.order.second_round_discount : 0) + Number(this.order.bin_cant_discount > 0 ? this.order.bin_cant_discount : 0) + Number(this.order.all_store_discount > 0 ? this.order.all_store_discount : 0) - Number(this.order.total_delivery > 0 ? this.order.total_delivery : 0) - Number(this.order.tip > 0 ? this.order.tip : 0) - Number(this.order.gift_price > 0 ? this.order.gift_price : 0) + Number(this.amountSplit > 0 ? this.amountSplit : 0)).toFixed(2) ? this.isTotalBarllenoFail = false : this.isTotalBarllenoFail = true

		// fin de ajuste de orden para mostrar detalles de facturacion TOTALES 

		if (this.order.scheduled) {
			this.order.schedule_time = this.order.schedule_time.toDate();
			this.order.schedule_date = this.order.schedule_date.toDate();
		}

		this.options.unshift();

		// recuperando informacion de los Colaboradores (delivery)
		this.api.getAllDocuments(`delivery`).then(data => {
			if (data) {
				data.forEach(element => {
					if (element['countryCode'] == this.auth.countryCode && element['active'] == true) {

						let arr = []
						// console.log(element)
						arr['id'] = element.$key
						arr['lastname'] = element.lastname
						arr['name'] = element.name
						arr['phonenumber'] = element.phonenumber

						this.options.push(arr);
					}

				});
			}
		});
	}

	@ViewChild('numFactura', { static: false }) numFactura: IonInput;

	onlyNumbers(event: any) {
		const charCode = (event.query) ? event.query : event.keyCode;
		if (charCode >= 48 && charCode <= 57) {
			return true;
		} else {
			return false;
		}
	}

	async guardarFactura(order: any) {
		// console.log("guardarFactura order = ", order)
		const resp = await this.ordersService.updateOrder(order.$key, this.numFactura.value.toLocaleString());
		if (resp == false) {
		} else {
			this.order = await this.ordersService.getOrderById(order.number);
			this.numFactura.value = '';
			this.modalController.dismiss();
		}
	}

	onChange(evt) {
		// console.log(typeof (this.options))
		// console.log(evt)
		this.phone = evt

		// if (evt == -1) {
		//     this.items = this.options.map(x => x.id);
		// } else {
		//     let selectAllIndex = this.items.indexOf(-1);
		//     this.items.splice(selectAllIndex, 1);
		// }
	}

	changeCalled(type) {
		if (this.phone) {
			openInNewTab(type, this.phone, this.order.address.lat, this.order.address.lng)
		} else {
			this.SwalService.fire(
				'Seleccione Datos de Delivery',
				'Registre en Menu -> Delivery. Los datos #CodigoPais + Telefono',
				'warning'
			)
		}
	}

	ngOnInit() { }

	ionViewWillEnter() {
		this.forceUpdate();
	}

	forceUpdate() {
		this.zone.run(() => {
			// console.log('force update the screen');
		});
	}

	async seeBrick(brick) {

		const modal = await this.modalController.create({
			component: BrickPreviewComponent,
			cssClass: 'addModal',
			componentProps: {
				brick: brick.brick
			}
		});
		return await modal.present();
	}

	async loader() {
		return await this.loadingCtrl.create({
			spinner: 'lines-small',
			animated: true,
			mode: 'ios',
			translucent: false,
			cssClass: 'custom-class custom-loading',
		});
	}

	captureSplit(split) {
		this.loader().then(loader => {
			loader.present().then(() => {

				this.SwalService.fire({
					title: 'Esta seguro que deseas Aceptar la transferencia?',
					text: "¡No podrás revertir esto!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, estoy seguro'
				}).then((result) => {
					if (result.value) {
						let getData = `orderNo=split-${split.$key}&amount=${split.amount}&type=1`;
						let url;

						url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

						this.http.get(url)
							.subscribe((response) => {
								if (response['TransactionModificationResult']['ResponseCode'] == 1) {

									this.api.updateDocument('splits', split.$key, {
										'transaction_status': 'captured',
									}).then(data => {
										split.transaction_status = 'captured';
										this.SwalService.fire(
											'Transacción Capturada',
											'Captura de fondos realizar con exito',
											'success'
										)
									})

									this.SwalService.fire(
										'Transacción Capturada',
										'Captura de fondos realizar con exito',
										'success'
									)
								} else {
									this.SwalService.fire(
										'Error!',
										'No se pudo completar la captura de la transacción, comunicate con el equipo de barlleno',
										'error'
									)
								}
								loader.dismiss();
							}, err => {
								loader.dismiss();
							});
					} else {
						loader.dismiss();
					}
				});
			})
		})
	}

	capture(order) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
			})
		};

		this.loader().then(loader => {
			loader.present().then(() => {
				this.SwalService.fire({
					title: 'Esta seguro que deseas Aceptar la transferencia?',
					text: "¡No podrás revertir esto!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, estoy seguro'
				}).then((result) => {

					if (result.value) {
						// let getData = `orderNo=${order.number}&amount=${order.total}&type=1`;
						let getData = `orderNo=${order.number}&split-${order.$key}&amount=${order.total}&type=1`;
						let transaction_id = order.transaction_id;
						let url;
						if (this.auth.countryCode == 'SV') {
							url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

						} else if (this.auth.countryCode == 'GT') {
							url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
						} else if (this.auth.countryCode == 'CO') {
							url = ((`https://production.wompi.co/v1/transactions/${transaction_id}`));
						}

						if (this.auth.countryCode !== 'CO') {
							this.http.get(url)
								.subscribe((response) => {
									if (response['TransactionModificationResult']['ResponseCode'] == 1) {
										this.api.updateDocument('orders', order.$key, {
											'transaction_status': 'captured',
											'status': 'making'
										}).then(data => {
											order.transaction_status = 'captured';
											this.SwalService.fire(
												'Transacción Capturada',
												'Captura de fondos realizar con exito',
												'success'
											)
										})
									} else {
										this.SwalService.fire(
											'Error!',
											'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
											'error'
										)
									}
									loader.dismiss();
									this.forceUpdate();
									this.router.navigate(['/monitor'])
								}, err => {
									loader.dismiss();
								});
						}


						else {
							this.http.get(url, httpOptions)
								.subscribe((response) => {
									if (response['data']['status'] == 'APPROVED') {
										this.api.updateDocument('orders', order.$key, {
											'transaction_status': 'captured',
											'status': 'making'
										}).then(data => {
											order.transaction_status = 'captured';
											this.SwalService.fire(
												'Transacción Capturada',
												'Captura de fondos realizar con exito',
												'success'
											)
										})
									} else {
										this.SwalService.fire(
											'Error!',
											'No se pudo completar la captura de transaccion, comunicate con el equipo de barlleno',
											'error'
										)
									}
									loader.dismiss();
								}, err => {
									loader.dismiss();
								});
						}

					} else {
						loader.dismiss();
					}
				});
			})
		})
	}

	revertSplit(split) {
		this.loader().then(loader => {
			loader.present().then(() => {
				this.SwalService.fire({
					title: 'Esta seguro que deseas Revertir la transferencia?',
					text: "¡No podrás revertir esto!",
					icon: 'error',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, estoy seguro'
				}).then((result) => {
					if (result.value) {
						let getData = `orderNo=split-${split.$key}&amount=${split.amount}&type=3`;
						this.http.get(`https://payments.barlleno.app/transactionModification.php?${getData}`)
							.subscribe((response) => {
								if (response['TransactionModificationResult']['ResponseCode'] == 1) {
									this.api.updateDocument('splits', split.$key, {
										'transaction_status': 'Reverted',
									}).then(data => {
										split.transaction_status = 'Reverted';
										this.SwalService.fire(
											'Transacción Capturada',
											'Captura de fondos realizar con exito',
											'success'
										)
									})
									this.SwalService.fire(
										'Transacción Revertida',
										'Reversión de fondos realizar con exito',
										'success'
									)
								} else {
									this.SwalService.fire(
										'Error!',
										'No se pudo revertir la transacción, comunicate con el equipo de barlleno',
										'error'
									)
								}
								loader.dismiss();
							}, err => {
								loader.dismiss();
							});
					}
					else {
						loader.dismiss();
					}
				})
			})
		})
	}

	captureOrder() {
		this.loader().then(loader => {
			loader.present().then(() => {

				this.SwalService.fire({
					title: 'Esta seguro que deseas Aceptar la transferencia?',
					text: "¡No podrás revertir esto!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, estoy seguro'
				}).then((result) => {
					if (result.value) {
						let getData = `orderNo=${this.order.number}&amount=${this.order.total}&type=1`;
						let url;

						url = `https://payments.barlleno.app/transactionModification.php?${getData}`;

						this.http.get(url)
							.subscribe((response) => {
								if (response['TransactionModificationResult']['ResponseCode'] == 1) {
									this.api.updateDocument('orders', this.order.$key, {
										'transaction_status': 'captured',
										'status': 'making'
									}).then(data => {
										this.order.transaction_status = 'captured';
										this.SwalService.fire(
											'Transacción Capturada',
											'Captura de fondos realizar con exito',
											'success'
										)
									})
								} else {
									this.SwalService.fire(
										'Error!',
										'No se pudo completar la captura de la transacción, comunicate con el equipo de barlleno',
										'error'
									)
								}
								loader.dismiss();
							}, err => {
								loader.dismiss();
							});
					} else {
						loader.dismiss();
					}
				});
			})
		})
	}

	revertOrder() {
		this.loader().then(loader => {
			loader.present().then(() => {
				this.SwalService.fire({
					title: 'Esta seguro que deseas Revertir la transferencia?',
					text: "¡No podrás revertir esto!",
					icon: 'error',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Si, estoy seguro'
				}).then((result) => {
					if (result.value) {
						let getData = `orderNo=${this.order.number}&amount=${this.order.total}&type=3`;
						this.http.get(`https://payments.barlleno.app/transactionModification.php?${getData}`)
							.subscribe((response) => {
								if (response['TransactionModificationResult']['ResponseCode'] == 1) {
									this.api.updateDocument('orders', this.order.$key, {
										'transaction_status': 'Reverted',
										'status': 'canceled'

									}).then(data => {
										this.order.transaction_status = 'Reverted';
										this.SwalService.fire(
											'Transacción Revertida',
											'Reversión de fondos realizar con exito',
											'success'
										)
									})
								} else {
									this.SwalService.fire(
										'Error!',
										'No se pudo revertir la transacción, comunicate con el equipo de barlleno',
										'error'
									)
								}
								loader.dismiss();
							}, err => {
								loader.dismiss();
							});
					}
					else {
						loader.dismiss();
					}
				})
			})
		})
	}

	sendEmailRequest() {
		// console.log("ingreso sendEmailRequest()")
		this.loader().then(loader => {
			loader.present().then(async () => {
				// console.log(`https://mails.barlleno.app/mails_inventoryTest.php?mode=notification_zendesk_order_fail&email=${window.btoa(JSON.stringify(this.auth.email))}&countryCode=${window.btoa(JSON.stringify(this.auth.countryCode))}&created_at=${window.btoa(JSON.stringify(new Date()))}&role=${this.auth.role}&order=${window.btoa(JSON.stringify(this.order))}`)
				await this.http.get(`https://mails.barlleno.app/mails_inventoryTest.php?mode=notification_zendesk_order_fail&email=${window.btoa(JSON.stringify(this.auth.email))}&countryCode=${window.btoa(JSON.stringify(this.auth.countryCode))}&created_at=${window.btoa(JSON.stringify(new Date()))}&role=${this.auth.role}&order=${window.btoa(JSON.stringify(this.order))}`)
					.subscribe((response) => {
						loader.dismiss();
						this.SwalService.fire('¡Listo!', 'Se ha enviado con éxito solicitud de Asistencia Técnica.', 'success');
					})
			})
		})
	}
}

function openInNewTab(type, phone, lat, lng) {
	if (type == 'google') {
		// google
		var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola,%20Esta%20es%20la%20localizacion%20del%20cliente%20%20https://www.google.com.ar/maps/place/${lat},${lng}`, '_blank');
		win.focus();
	} else {
		// waze
		// var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=hola,%20Esta%20es%20la%20localizacion%20del%20clinte%20%20https://www.google.com.ar/maps/place/${lat},${lng}`, '_blank');
		var win = window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola,%20Esta%20es%20la%20localizacion%20del%20cliente%20%20https://waze.com/ul?ll=${lat},${lng}&z=10`, '_blank');
		win.focus();
	}
}