import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, NavParams } from '@ionic/angular';
import { SwalService } from 'src/app/services/alert/swal.service';
import { ApiService } from 'src/app/services/api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-cancel-this.order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss'],
})
export class CancelOrderComponent implements OnInit {

  texCancelOrder: String = ""
  authorized: String = ""
  textMessage: String = ""
  textMessage2: String = ""
  order: any;
  revertTransaction: boolean = false;
  constructor(
	public api: ApiService,
	public loadingCtrl: LoadingController,
	public modalController: ModalController,
    public navParams: NavParams,
	public auth: AuthService,
	private http: HttpClient,
    private SwalService: SwalService
  ) { 
    this.order = navParams.get('order');
    this.revertTransaction = navParams.get('revertTransaction');
  }

  ngOnInit() {

  }

  async cancelOrderSave (){
	if (this.texCancelOrder == "" ){
		this.textMessage ="Es necesario ingresar el motivo"
		return
	}
	if (this.texCancelOrder.length < 20 ){
		this.textMessage = "El mínimo de caracteres es de 20"
		return
	}
	if (this.texCancelOrder.length > 256) {
		this.textMessage ="El maximo de caracteres es 256"
		return
	}
	if (this.authorized.length < 10 ){
		this.textMessage2 ="El minimo de caracteres para la persona es 10"
		return
	}

	
	this.SwalService.fire({
		title: `¿Estas seguro que deseas CANCELAR la orden ${this.order.number}?`,
		text: "¡No podrás revertir esto!",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Si, estoy seguro'
	}).then(async (result) => {
		if (result.value) {
			this.loader().then(loader => {
				loader.present().then(async () => {
					let data = {
						status: 'canceled',
						points_revert_applied: true,
						canceledReason: this.texCancelOrder,
						authorizedBy: this.authorized,
						canceledBy: {
							email: this.auth.email,
							date: new Date()
						}
					}
					this.api.updateDocument("orders", this.order.$key, data).then((res)=> {
						loader.dismiss();
						this.SwalService.fire(
							'Estado actualizado!',
							'Orden ha sido cancelada correctamente.',
							'success'
						)
						this.modalController.dismiss();
					})
                    //Actualizacion de inventarios
                    let promises = []
                    for (let i = 0; i < this.order.bricks.length; i++) {
                        let brickOrder = this.order.bricks[i]
                        let brickPresentation = await this.api.getDocument(`bricks/${brickOrder.brick_key}/presentations`, brickOrder.$key).then((response)=>{
                            return response
                        })
                        let inventory = isNaN(brickPresentation['inventory']) ? 0 : Number(brickPresentation['inventory'])
                        let newInventory = isNaN(brickOrder['quantity']) ? 0 : Number(brickOrder['quantity'])
                        brickPresentation['inventory'] = inventory + newInventory
                        promises.push(this.api.updateDocument(`bricks/${brickOrder.brick_key}/presentations`, brickOrder.$key, brickPresentation))

						if(this.order.bricks[i].hasOwnProperty('marketOption')){
							let brickOrder = this.order.bricks[i].marketOption[1]
							let brickPresentation = await this.api.getDocument(`bricks/${brickOrder.brick_key}/presentations`, brickOrder.$key).then((response)=>{
								return response
							})
							let inventory = isNaN(brickPresentation['inventory']) ? 0 : Number(brickPresentation['inventory'])
							let newInventory = isNaN(brickOrder['quantity']) ? 0 : Number(brickOrder['quantity'])
							brickPresentation['inventory'] = inventory + newInventory
							promises.push(this.api.updateDocument(`bricks/${brickOrder.brick_key}/presentations`, brickOrder.$key, brickPresentation))
						}
                    }
                    await Promise.all(promises)
                    // En caso de puntos se le regresan al usaurio
                    if ( this.order.total_points > 0 ) {
                        let store:any = await this.api.getDocument(`stores`, this.auth.storeKey).then((response)=>{
                            return response
                        })
                        let get_points_store = (store.hasOwnProperty("active_shared_points") && !store.active_shared_points) ? false : true;// false = tomar pts tienda 
       
                        let  name_points = (get_points_store) ? (this.auth.countryCode == "SV") ? "points" : `points_${this.auth.countryCode }` : `points_${store.code_store}_${store.countryCode}`;
                        await this.api.getDocument(`accounts`, this.order.account.$key !== undefined ? this.order.account.$key : this.order.account ).then((response)=>{
                            this.api.updateDocument("accounts", this.order.account.$key !== undefined ? this.order.account.$key : this.order.account, {
                                [name_points] : response[name_points] +  this.order.total_points 
                            })
                        })
                    }

					if ( this.revertTransaction ) {
						
						let httpOptions = {
							headers: new HttpHeaders({
								'Authorization': 'Bearer prv_prod_PWw13Ai1yGYJuTF6BQJ99nG8w9MkTHAw'
							})
						};
							let getData = `orderNo=${this.order.number}&amount=${this.order.total}&type=3`;
							let url;
							let transaction_id = this.order.transaction_id;
							let method = 'void';
							let azulId = this.order.CustomOrderId;
							if (this.auth.countryCode == 'CO') {
								url = `(https://production.wompi.co/v1/transactions/?${transaction_id})/${method}`;
							} else if (!this.order.hasOwnProperty('TransactionIdentifier') && this.auth.countryCode == 'SV') {
								url = `https://payments.barlleno.app/transactionModification.php?${getData}`;
							} else if (!this.order.hasOwnProperty('TransactionIdentifier') && this.auth.countryCode == 'GT') {
								url = `https://payments.barlleno.app/gt/transactionModification.php?${getData}`;
							} else if (this.order.hasOwnProperty('TransactionIdentifier') && (this.auth.countryCode == 'SV' || this.auth.countryCode == 'GT' || this.auth.countryCode == 'RAF')) {
								// return 
								// nuevo FAC 3DSv2 
								httpOptions = {
									headers: new HttpHeaders({
										'Access-Control-Allow-Origin': 'origin',
										'application': 'x-www-form-urlencoded',
										'Content-type': 'application/json',
										'Access-Control-Allow-Credentials': 'true',
										'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
										'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
									})
								};

								let body = {
									"TransactionIdentifier": this.order.TransactionIdentifier,
									"ExternalIdentifier": `${this.order.number}`
								}

								url = `https://payments.paggi.app/powerTranz/${this.auth.countryCode}/Void.php`;

								this.http.post(url, JSON.stringify(body), httpOptions)
									.subscribe((response) => {
										if (response.hasOwnProperty('IsoResponseCode') && response['IsoResponseCode'] == '00') {
											this.api.updateDocument('orders', this.order.$key, {
												'transaction_status': 'void',
												'transaction_void_date': new Date(),
												'status': 'canceled'
											}).then(data => {
												this.order.transaction_status = 'captured';
												this.SwalService.fire(
													'Transacción Anulada',
													'Anulación de fondos realizar con exito',
													'success'
												)
											})
										} else {
											this.SwalService.fire(
												'Error!',
												`No se pudo completar Anulación de transaccion, comunicate con el equipo de barlleno ${response['ResponseMessage']}`,
												'error'
											)
										}
										loader.dismiss();
									}, err => {
										loader.dismiss();
									});
							}
							// agregando metodo de revertir transaccion DO
							else if (this.auth.countryCode == 'DO') {
								url = `https://paymentsdr.barlleno.app/src/Azul/voidSale.php?${azulId}`;
								httpOptions = {
									headers: new HttpHeaders({
										'Access-Control-Allow-Origin': 'origin',
										'application': 'x-www-form-urlencoded',
										'Content-type': 'application/json',
										'Access-Control-Allow-Credentials': 'true',
										'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Origin, Cache-Control, Pragma, Authorization, Accept, Accept-Encoding',
										'Access-Control-Allow-Methods': 'PUT, POST, GET, OPTIONS, DELETE'
									})
								};

							}

							if (this.auth.countryCode !== 'CO') {
								this.http.get(url)
									.subscribe((response) => {
										if (response['TransactionModificationResult']['ResponseCode'] == 1) {
											this.api.updateDocument('orders', this.order.$key, {
												'transaction_status': 'Reverted',
												'status': 'canceled'

											}).then(data => {
												this.order.transaction_status = 'reverted';
												this.SwalService.fire(
													'Transacción Revertida',
													'Reversión de fondos realizar con exito',
													'success'
												)
											})
										} else {
											this.SwalService.fire(
												'Error!',
												'No se pudo completar, comunicate con el equipo de Barlleno',
												'error'
											)
										}
										loader.dismiss();
									}, err => {
										loader.dismiss();
									});
							}

							else {
								this.http.get(url, httpOptions)
									.subscribe((response) => {
										if (response['data']['transaction']['status'] == 'VOIDED') {
											this.api.updateDocument('orders', this.order.$key, {
												'transaction_status': 'Reverted',
												'status': 'canceled'

											}).then(data => {
												this.order.transaction_status = 'reverted';
												this.SwalService.fire(
													'Transacción Revertida',
													'Reversión de fondos realizar con exito',
													'success'
												)
											})
										} else {
											this.SwalService.fire(
												'Error!',
												'No se pudo completar, comunicate con el equipo de Barlleno',
												'error'
											)
										}
										loader.dismiss();
									}, err => {
										loader.dismiss();
									});
							}
					
					} else {
						loader.dismiss();
					}
				})
			})
		}
	})
	

  }
  
	async loader() {
		return await this.loadingCtrl.create({
			spinner: 'lines-small',
			animated: true,
			mode: 'ios',
			translucent: false,
			cssClass: 'custom-class custom-loading',
		});
	}

}
