import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { IonContent, ActionSheetController, ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RealtimeApiService } from 'src/app/services/realtimeApi/realtime-api.service';
import * as firebase from 'firebase';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {

    userId: any;
    title: any;
    message: any;
    conversationId: any;
    updateDateTime: any;
    messagesToShow: any;
    startIndex: any = -1;
    // Set number of messages to show.
    numberOfMessages = 10;
    loggedInUserId: any;
    friend: any;
    messages: any;
    account: any;

    constructor(
        public formBuilder: FormBuilder,
        public api: ApiService,
        public route: ActivatedRoute,
        public auth: AuthService,
        public dataProvider: RealtimeApiService,
        private zone: NgZone,
        public actionSheetController: ActionSheetController,
        public angularfire: AngularFireDatabase,
        public modalController: ModalController,
        public navParams: NavParams
    ) {
        this.getConversation();
    }

    ngOnInit() {

    }

    

    getConversation() {
        console.log("hola")
        if(this.auth.countryCode == 'GT'){
            this.loggedInUserId = 'BarllenoGT';
        }else 
        if(this.auth.countryCode == 'HN'){
            this.loggedInUserId = 'BarllenoHN';
        }else
        if(this.auth.countryCode == 'DO'){
            this.loggedInUserId = 'BarllenoDO';
        }else
        if(this.auth.countryCode == 'CO'){
            this.loggedInUserId = 'BarllenoCO';
        }else
        if(this.auth.countryCode == 'SV'){
            this.loggedInUserId = 'Barlleno';
        }    


          this.account = this.route.snapshot.paramMap.get('conversation_key');
    console.log("deal_key", this.account);
          

        this.api.getDocument('accounts', this.userId).then(data => {
            this.friend = data;
            this.friend.push_keys = [];
            this.api.getAllDocuments(`accounts/${this.friend.$key}/push_keys`).then(keys => {
                if (keys) {
                    keys.forEach(element => {
                        this.friend.push_keys.push(element.push_key);
                    });
                }
            })

        })

        



        // Get conversationInfo with friend.
        this.angularfire.object('/accounts/' + this.loggedInUserId + '/conversations/' + this.userId).snapshotChanges().subscribe({
            next: (conversation: any) => {
            if (conversation.payload.exists()) {
                // User already have conversation with this friend, get conversation
                this.conversationId = conversation.payload.val().conversationId;

                // Get conversation
                this.dataProvider.getConversationMessages(this.conversationId).snapshotChanges().subscribe((messagesRes: any) => {

                    let messages = messagesRes.payload.val();
                    if (messages == null)
                        messages = [];
                    if (this.messages) {
                        // Just append newly added messages to the bottom of the view.
                        if (messages.length > this.messages.length) {
                            let message = messages[messages.length - 1];
                            this.messages.push(message);
                            this.messagesToShow.push(message);
                        }
                    } else {
                        // Get all messages, this will be used as reference object for messagesToShow.
                        this.messages = [];

                        messages.forEach((message) => {
                            this.messages.push(message);
                        });
                        // Load messages in relation to numOfMessages.
                        if (this.startIndex == -1) {
                            // Get initial index for numberOfMessages to show.
                            if ((this.messages.length - this.numberOfMessages) > 0) {
                                this.startIndex = this.messages.length - this.numberOfMessages;
                            } else {
                                this.startIndex = 0;
                            }
                        }
                        if (!this.messagesToShow) {
                            this.messagesToShow = [];
                        }
                        // Set messagesToShow
                        for (var i = this.startIndex; i < this.messages.length; i++) {
                            this.messagesToShow.push(this.messages[i]);
                        }
                        this.setMessagesRead();
                    }
                });
            }
            },
            error: (error) =>{
                if (error.code === 'permission-denied') {
                    console.error('No tienes permiso para acceder a este documento');
                } else {
                    console.error(error);
                }
             }
        });

        // Update messages' date time elapsed every minute based on Moment.js.
        var that = this;
        if (!that.updateDateTime) {
            that.updateDateTime = setInterval(function () {
                if (that.messages) {
                    that.messages.forEach((message) => {
                        let date = message.date;
                        message.date = new Date(date);
                    });
                }
            }, 60000);
        }
    }

    // Update messagesRead when user lefts this page.
    ionViewWillLeave() {
        this.setMessagesRead();
    }

    // Update messagesRead when user lefts this page.
    ionViewDidLeave() {
        this.setMessagesRead();
    }

    ngOnDestroy() {
        this.setMessagesRead();
    }

    // Check if currentPage is active, then update user's messagesRead.
    setMessagesRead() {
        firebase.database().ref('/conversations/' + this.conversationId + '/messages').once('value', snap => {

            if (snap.val() != null) {
                this.angularfire.object('/accounts/' + this.loggedInUserId + '/conversations/' + this.userId).update({
                    messagesRead: snap.val().length
                }).catch((error)=> {
                    if (error.code === 'permission-denied') {
                        console.error('No tienes permiso para acceder a este documento');
                    } else {
                        console.error(error);
                    }
                });
            }
        }).catch((error)=> {
            if (error.code === 'permission-denied') {
                console.error('No tienes permiso para acceder a este documento');
            } else {
                console.error(error);
            }
        });
    }

    send() {
        if (this.message) {
            // User entered a text on messagebox
            if (this.conversationId) {
                let messages = JSON.parse(JSON.stringify(this.messages));
                messages.push({
                    date: new Date().toString(),
                    sender: this.loggedInUserId,
                    message: this.message
                });
                // Update conversation on database.
                this.dataProvider.getConversation(this.conversationId).update({
                    messages: messages
                }).then(data => {
                    this.api.getAllDocuments(`accounts/${this.userId}/push_keys`).then(keys => {
                        if (keys) {
                            let push_keys = [];
                            keys.forEach(element => {
                                push_keys.push(element.push_key);
                            });
                            //console.log(this.message);

                            this.api.sendPush(`Nuevo mensaje`, this.message, push_keys).then(data => {
                                this.message = '';
                            }, err => {
                                this.message = '';
                            })
                        }
                    })
                })
                // Clear messagebox.
                // this.api.sendPush(`${this.account.username}`, this.message, this.friend.push_keys);
            } else {
                // New Conversation with friend.
                var messages = [];
                messages.push({
                    date: new Date().toString(),
                    sender: this.loggedInUserId,
                    message: this.message
                });
                var users = [];
                users.push(this.loggedInUserId);
                users.push(this.userId);
                // Add conversation.
                this.angularfire.list('conversations').push({
                    dateCreated: new Date().toString(),
                    messages: messages,
                    users: users
                }).then((success) => {
                    let conversationId = success.key;
                    // this.api.sendPush(`${this.account.username}`, this.message, this.friend.push_keys);
                    this.api.getAllDocuments(`accounts/${this.userId}/push_keys`).then(keys => {
                        if (keys) {
                            let push_keys = [];
                            keys.forEach(element => {
                                push_keys.push(element.push_key);
                            });

                            this.api.sendPush(`Nuevo mensaje`, this.message, push_keys).then(data => {
                                this.message = '';
                            }, err => {
                                this.message = '';
                            })
                        }
                    })
                    // Add conversation reference to the users.
                    this.angularfire.object('/accounts/' + this.loggedInUserId + '/conversations/' + this.userId).update({
                        conversationId: conversationId,
                        messagesRead: 1
                    }).catch(error =>{
                        if (error.code === 'permission-denied') {
                            console.error('No tienes permiso para acceder a este documento');
                        } else {
                            console.error(error);
                        }
                    });
                    this.angularfire.object('/accounts/' + this.userId + '/conversations/' + this.loggedInUserId).update({
                        conversationId: conversationId,
                        messagesRead: 0
                    }).catch(error =>{
                        if (error.code === 'permission-denied') {
                            console.error('No tienes permiso para acceder a este documento');
                        } else {
                            console.error(error);
                        }
                    });

                });
            }
        }
    }

    isSender(message) {
        if (message.sender == this.loggedInUserId) {
            return true;
        } else {
            return false;
        }
    }

    isLast(type, i) {
        if (type == 'mine') {
            if (this.messagesToShow[i + 1] && this.messagesToShow[i + 1].sender !== this.loggedInUserId) {
                return 'last';
            
            }else{
                return 'last';
            }
        } else {
            if (this.messagesToShow[i + 1] && this.messagesToShow[i + 1].sender == this.loggedInUserId) {
                return 'last';
            }else {
                return 'last';
            }
        }

    }



}
