import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SwalService } from 'src/app/services/alert/swal.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
    selector: 'app-partner-settings',
    templateUrl: './partner-settings.component.html',
    styleUrls: ['./partner-settings.component.scss'],
})
export class PartnerSettingsComponent implements OnInit {

    partners: Array<any> = [];
    category: any = {}
    partnersCategory: Array<any> = [];
    idStorageCategory:Array<any> = [];


    constructor(
        public api: ApiService,
        public formBuilder: FormBuilder,
        public loadingCtrl: LoadingController,
        public navParams: NavParams,
        public modalController: ModalController,
        public auth: AuthService,
        private SwalService: SwalService
    ) {
        this.partners = navParams.get('partner');
        this.category = navParams.get('category');
        this.partnersCategory = navParams.get('partnersCategory');
        

        this.partnersCategory.forEach(element => {
            this.idStorageCategory.push(element.partner_key)
        });       
    }

    mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
        Swal.fire({
            position: 'top-end',
            icon: icono,
            title: mensaje,
            showConfirmButton: false,
            timer: 3500,
            toast: true
        });
    }

    ngOnInit() {    
        this.visibilityChangement()     
    }

    visibilityChangement(){
        this.partners.forEach((partnerGeneral) => {
            this.partnersCategory.forEach((partnerCategory)=>{
                if(partnerCategory.partner_key == partnerGeneral.$key){
                    partnerGeneral.visible = partnerCategory.visible
                }
            })
        });
    }

    visibilityPartner(partner: any) {
        partner.visible = !partner.visible;
    
        this.partnersCategory = this.partnersCategory.map((element) => {
            if (element.partner_key === partner.$key) {
                return { ...element, visible: partner.visible };
            } else {
                return element;
            }
        });
    }


    async loader() {
        return await this.loadingCtrl.create({
            spinner: 'lines-small',
            animated: true,
            mode: 'ios',
            translucent: false,
            cssClass: 'custom-class custom-loading',
        });
    }

    updatePartner() {
        
    }

    changePartner(e: any, partner: any) {
        let repeated = this.partnersCategory.findIndex((x: any) => x.partner_key == partner.$key);

        if (e.detail.checked == true) {
            if (repeated == -1) {
                this.partnersCategory.push({ name: partner.name, partner_key: partner.$key, visible: true });
            }
            partner.visible = true;
        } else {
            if (repeated != -1) {
                this.partnersCategory = this.partnersCategory.filter(function(x) { return x.partner_key != partner.$key; });
                partner.visible = false;
            }
        }
    }
    

    savePartnersCategory(){
        console.log(this.category.$key,this.partnersCategory )
        this.api.updateDocument("bricks", this.category.$key, {visible_partner: this.partnersCategory}).then(()=>{
            this.mensajeAlerta("success", "El socio fue añadido a la categoría exitosamente!")
            this.modalController.dismiss(true);
        })
    }
}
