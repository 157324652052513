import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
    { 
        path: '', 
        redirectTo: '/login',
        pathMatch: 'full' 
    },{
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },{
        path: 'control-inventory/:event_key',
        loadChildren: () => import('./pages/control-inventory/control-inventory.module').then(m => m.ControlInventoryPageModule)
    },{
        path: 'index',
        loadChildren: () => import('./pages/index/index.module').then( m => m.IndexPageModule)
    },{
        path: 'orders/commands',
        loadChildren: () => import("./pages/orders-events/orders-events.module").then(m => m.OrdersEventsPageModule)
    },  {
    path: 'lupe-reyes',
    loadChildren: () => import('./pages/lupe-reyes/lupe-reyes.module').then( m => m.LupeReyesPageModule)
  },
  {
    path: 'dashboard-trade',
    loadChildren: () => import('./pages/dashboard-trade/dashboard-trade.module').then( m => m.DashboardTradePageModule)
  },



    // {
    //     path: 'import-csv',
    //     loadChildren: () => import('./pages/import-csv/import-csv.module').then(m => m.ImportCsvPageModule)
    // },
    // {
    //     path: 'import-csv-sap',
    //     loadChildren: () => import('./pages/import-csv-sap/import-csv-sap.module').then(m => m.ImportCsvSapPageModule)
    // },
    // {
    //     path: 'verify-payment',
    //     loadChildren: () => import('./pages/verify-payment/verify-payment.module').then(m => m.VerifyPaymentPageModule)
    // },
    // {
    //     path: 'shedule-collaborator',
    //     loadChildren: () => import('./pages/shedule-collaborator/shedule-collaborator.module').then(m => m.SheduleCollaboratorPageModule)
    // },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: NoPreloading
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
