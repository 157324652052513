import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoadingController, NavParams, ModalController } from '@ionic/angular';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { of } from 'rxjs';
import { AddReferenceComponent } from '../add-reference/add-reference.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { SwalService } from 'src/app/services/alert/swal.service';

@Component({
	selector: 'app-add-brick-mixology',
	templateUrl: './add-brick-mixology.component.html',
	styleUrls: ['./add-brick-mixology.component.scss'],
})

export class AddBrickMixologyComponent implements OnInit {
	brickForm: FormGroup;
	brick: any;
	type: any = 'brick';
	severity: number;
	nameBrick: any;
	brick_image: any = false;
	ingredients: any = [];
	recommended: any = [];
	category: any;
	ingredientName: any
	typeCocktail: any
	preparation: any
	makingTime: any;
	nameMainIngredient: any = 'N / D'
	// receta de referencia
	// /explore/J28PS7LFyLnS0tCavubg

	constructor(
		public api: ApiService,
		public formBuilder: FormBuilder,
		public loadingCtrl: LoadingController,
		public navParams: NavParams,
		public auth: AuthService,
		private http: HttpClient,
		public modalController: ModalController,
        private SwalService: SwalService
	) {
		this.brick = navParams.get('brick');
		this.nameBrick = navParams.get('name');
		if (this.brick) {
			this.severity = this.brick.severity
			//console.log(this.severity)
			this.makingTime = this.brick.makingTime
			this.typeCocktail = this.brick.typeCocktail
			//console.log(this.typeCocktail)
			this.preparation = this.brick.preparation
			//console.log(this.preparation)
		}
		if (navParams.get('type')) {
			this.type = navParams.get('type');
			//console.log(this.type)
		}

		this.brickForm = this.formBuilder.group({
			nameIngredient: [this.brick != undefined ? this.brick.name : this.nameBrick, [Validators.required]],
			makingTime: [this.brick != undefined ? Number(this.brick.makingTime) : '', [Validators.required]],
			severity: [this.brick != undefined ? this.brick.severity : '', [Validators.required]],
			typeCocktail: [this.brick != undefined ? this.brick.typeCocktail : '', [Validators.required]],
			preparation: [this.brick != undefined ? this.brick.preparation : '', [Validators.required]],
		});

		if (this.type == 'brick') {
			if (this.brick != undefined) {
				this.api.getRef(`explore/${this.brick.$key}/ingredients`).ref
					.onSnapshot(snapshots => {
						snapshots.docChanges().forEach(element => {
							if (element.type == 'added') {
								let ingredient = element.doc.data();
								ingredient.$key = element.doc.id;
								//console.log("ingreso a recuperar los ingredientes", ingredient)
								if (ingredient.main == true) {
									this.nameMainIngredient = ingredient.name
								}
								this.ingredients.push(ingredient);
							}
						});
					})
			}
		}
		this.getReferences();
	}

	ngOnInit() { }

	async loader() {
		return await this.loadingCtrl.create({
			spinner: 'lines-small',
			animated: true,
			mode: 'ios',
			translucent: false,
			cssClass: 'custom-class custom-loading',
		});
	}

	uploadedImages(file) {
		this.brick_image = {
			name: file.file.name,
			src: file.src
		};
	}

	removeImages() {
		this.brick_image = false;
	}

	onSelectChangeSeverity(selectedValue: any) {
		//console.log(selectedValue)
		this.severity = selectedValue;
	}

	onSelectChangeTypeCocktail(selectedValue: any) {
		//console.log(selectedValue)
		this.typeCocktail = selectedValue;
	}

	onSelectChangePreparation(selectedValue: any) {
		//console.log(selectedValue)
		this.preparation = selectedValue;
	}

	getReferences() {
		if (this.brick != undefined) {
			this.recommended = [];
			this.api.getRef(`recommended/`).ref
				.where('brick', '==', this.brick.$key)
				.get()
				.then(snapshots => {
					snapshots.forEach(element => {

						let recommended = element.data();
						recommended.$key = element.id;
						//console.log(recommended);


						this.api.getDocument('bricks', element.data().reference).then(data => {
							recommended.brick = data;
							this.recommended.push(recommended);
						}, err => {
							this.api.deleteDocument('bricks', recommended.$key)
						});

					});
				})
		}
	}

	async addReference() {
		// if (this.recommended.length >= 2) {
		// 	// //console.log("this.recommended.length", this.recommended.length)
		// 	this.mensajeAlerta('error', 'El máximo de referencias es 2, elimine una para continuar');
		// 	return
		// }

		const modal = await this.modalController.create({
			component: AddReferenceComponent,
			cssClass: 'addModal',
			componentProps: {
				category: this.category,
			}
		});
		modal.onDidDismiss().then(data => {
			let referenciaKey = data.data.$key
			if (!data.data.hasOwnProperty('$key')) {
				referenciaKey = data.data.objectID
			}
			if (data.data) {
				if (this.brick != undefined && this.brick.hasOwnProperty('$key')) {
					let brick = {
						brick: this.brick.$key,
						visible: data.data.visible,
						reference: referenciaKey,
					}
					//console.log(brick);

					this.loader().then(loader => {
						loader.present().then(() => {
							this.api.addDocument('recommended', brick).then(data => {
								loader.dismiss();
								this.getReferences();
                                this.api.addDocument('recommended/'+data.id+'/history', {
                                    action: 'createdReferenceRecomended',
                                    date: new Date(),
                                    user: this.auth.email,
                                    newData: brick
                                })
								this.SwalService.fire('¡Listo!', 'Tu Referencia ha sido agregada', 'success');
							})
						})
					})
				} else {
					this.api.getDocument('bricks', referenciaKey).then(dataResp => {
						let recommended = [];
						let brick = {
							// brick: this.brick.$key, // no existe en este momento porque no he guardado el brick mixology
							visible: data.data.visible,
							reference: referenciaKey
						}

						recommended['brick'] = dataResp;
						recommended['visible'] = data.data.visible;
						recommended['reference'] = referenciaKey;

						this.recommended.push(recommended);

					}, err => {
						//console.log("error en ")
					});

				}


			}
		})
		return await modal.present();
	}

	deleteReference(reference) {
		if (this.brick != undefined && this.brick.hasOwnProperty('$key')) {

			reference.description = unescape(encodeURIComponent(reference.description))
			reference.name = unescape(encodeURIComponent(reference.name))

			this.api.deleteDocument('recommended', reference.$key).then(data => {
				this.getReferences();
				//console.log(`https://mails.barlleno.app/mails_inventory.php?mode=deleteLog&data=${window.btoa(JSON.stringify(reference))}&role=${this.auth.role}`);
				this.http.get(`https://mails.barlleno.app/mails_inventory.php?mode=deleteLog&data=${window.btoa(JSON.stringify(reference))}&role=${this.auth.role}`)
					.subscribe((response) => {
					})
			})
		} else {
			// reference
			//console.log("--> deleteReference", reference)
			//console.log("reference", reference, "this.recommended", this.recommended)
			const newRecommended = this.recommended.filter(item => item.reference !== reference.reference);
			//console.log("newRecommended", newRecommended)
			this.recommended = newRecommended
		}

	}

	changeMain(ingredient, $event) {
		this.ingredients.forEach(element => {
			if (ingredient.$key == element.$key) {
				element.main = $event.detail.checked
				this.nameMainIngredient = element.name
			} else {
				element.main = false;
			}
		});
	}

	removeIngredient(ingredient) {
		this.SwalService.fire({
			title: 'Estás seguro que deseas eliminar?',
			text: "¡No podrás revertir esto!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sí, estoy seguro'
		}).then((result) => {
			if (result.value) {
				if (this.brick != undefined && this.brick.hasOwnProperty('$key')) {
					this.api.deleteDocument(`explore/${this.brick.$key}/ingredients/`, ingredient.$key).then(data => {

						for (let index = 0; index < this.ingredients.length; index++) {
							const element = this.ingredients[index];
							if (element.$key == ingredient.$key) {
								this.ingredients.splice(index, 1)
							}
						}
						this.SwalService.fire(
							'Eliminado!',
							'Ingrediente eliminado',
							'success'
						)
					});
				} else {
					//console.log("ingredient", ingredient)
					const newIngredients = this.ingredients.filter(item => item.name !== ingredient.name);
					//console.log("newIngredients", newIngredients)
					this.ingredients = newIngredients
					this.SwalService.fire(
						'Eliminado!',
						'Ingrediente eliminado',
						'success'
					)
				}
			}
		})
	}

	addIngredient() {
		//console.log("this.brick", this.brick)

		if (this.brick != undefined && this.brick.hasOwnProperty('$key')) {
			//console.log("addIngrediente in brick exist")
			this.api.addIngredient(`explore/${this.brick.$key}/ingredients`, {
				name: this.ingredientName,
				amount: "0",
				measure: "",
			}).then(data => {
				//console.log("data", data)
				this.ingredientName = '';
			})
		} else {
			this.ingredients.push({
				name: this.ingredientName,
				amount: "0",
				measure: "",
			});
			this.ingredientName = '';
		}
	}
	onChangeMakingTime(selectedValue: any) {
		//console.log(selectedValue)
		this.makingTime = selectedValue
	}

	// ==================== Mensajes de alerta ==================== 
	mensajeAlerta(icono: SweetAlertIcon, mensaje: string) {
		Swal.fire({
			position: 'top-end',
			icon: icono,
			title: mensaje,
			showConfirmButton: false,
			timer: 3500,
			toast: true
		});
	}

	reviewValidations(): boolean {

		if (this.brick == undefined && !this.brick_image.src) {
			this.mensajeAlerta('error', 'Favor cargar imagen de Mixology');
			return false;
		}
		if (this.makingTime == undefined || this.makingTime == '' || Number(this.makingTime) < 0) {
			this.mensajeAlerta('error', 'Favor completar campo de Tiempo de Preparacion');
			return false;
		}

		if (this.severity == undefined || Number(this.severity) <= 0) {
			this.mensajeAlerta('error', 'Favor completar campo de Dificultad');
			return false;
		}

		if (this.typeCocktail == undefined || Number(this.typeCocktail) <= 0) {
			this.mensajeAlerta('error', 'Favor completar campo de Tipo de Cocktail');
			return false;
		}

		if (!(this.ingredients.length > 0)) {
			this.mensajeAlerta('error', 'Favor agregar Ingredientes a receta Mixology');
			return false;
		}

		if (this.preparation == undefined || this.preparation == '' || !(this.preparation.length > 0)) {
			this.mensajeAlerta('error', 'Favor agregar procedimiento de Preparación');
			return false;
		}

		return true;

	}


	async addBrickMixology() {
		//console.log("this.nameMainIngredient -> ", this.nameMainIngredient)
		if (this.reviewValidations() == false) {
			// //console.log(this.formProducto.controls)
			return;
		}

		//console.log("addBrickMixology()", this.brick);
		if (this.brick != undefined && this.brick.hasOwnProperty('$key')) {
			//console.log("ya existe el brick")
			this.brick['makingTime'] = this.brickForm.value.makingTime
			this.brick['name'] = this.brickForm.value.nameIngredient
			this.brick['severity'] = this.brickForm.value.severity
			this.brick['typeCocktail'] = this.brickForm.value.typeCocktail
			this.brick['preparation'] = this.preparation
			this.brick['numIngredient'] = this.ingredients.length
		} else {
			this.brick = []
			// creando el brick
			//console.log("el brick es nuevo creando el objeto")
			//console.log(this.brickForm.value)
			//console.log("makingTime = ", this.brickForm.value.makingTime)
			this.brick['makingTime'] = this.brickForm.value.makingTime
			this.brick['name'] = this.brickForm.value.nameIngredient
			this.brick['severity'] = this.brickForm.value.severity
			this.brick['typeCocktail'] = this.brickForm.value.typeCocktail
			this.brick['preparation'] = this.preparation
			this.brick['numIngredient'] = this.ingredients.length
		}
		await new Promise(r => setTimeout(r, 500));
		//console.log("this.brick = ", this.brick, "this.ingredients = ", this.ingredients, "this.brick_image.src = ", this.brick_image.src)
		// return
		// return
		if (this.brick != undefined && this.brick.hasOwnProperty('$key')) {
			//console.log("el brick ya existe")
			this.loader().then(loader => {
				loader.present().then(() => {
					this.api.updateBrickMixology(this.brick, this.ingredients, this.brick_image.src).then(async data => {
						// 
						this.api.getRef(`catalogue/`).ref
							.where('name', '==', this.nameMainIngredient)
							.where('countryCode', '==', this.auth.countryCode)
							.where('category', '==', 'principalIngredient')
							.get()
							.then(snapshots => {
								if (snapshots.size > 0) {
									snapshots.forEach(element => {
										let item = element.data();
										item.$key = element.id;
										// //console.log("---element> ", item)
										let value = -1
										if(item.principalIngredient){
											value = item.principalIngredient.indexOf(this.brick.$key)
										}else{
											item.principalIngredient = []
										}
										// //console.log(value)
										if (value >= 0) {
										} else {
											// //console.log("no existe valor $key", item.principalIngredient)
											item.principalIngredient.push(this.brick.$key)
											// //console.log(item.principalIngredient)
											this.api.updateDocument('catalogue', item.$key, {
												'principalIngredient': item.principalIngredient,
											})
										}
									});
								} else {
									let obj = {
										'category': 'principalIngredient',
										'countryCode': this.auth.countryCode,
										'storeKey': this.auth.storeKey,
										'storeCode': this.auth.storeCode,
										'main': true,
										'name': this.nameMainIngredient,
										'type': 'catalogue'
									}
									this.api.addPrincipaIngredients(obj).then(data => {
										// 

										if (data) {
											this.api.getRef(`catalogue/`).ref
												.where('name', '==', this.nameMainIngredient)
												.where('countryCode', '==', this.auth.countryCode)
												.where('category', '==', 'principalIngredient')
												.get()
												.then(snapshots => {
													if (snapshots.size > 0) {
														snapshots.forEach(element => {
															let item = element.data();
															item.$key = element.id;
															// //console.log("---element> ", item)
															const value = item.principalIngredient.indexOf(this.brick.$key)
															// //console.log(value)
															if (value >= 0) {
															} else {
																// //console.log("no existe valor $key", item.principalIngredient)
																item.principalIngredient.push(this.brick.$key)
																// //console.log(item.principalIngredient)
																this.api.updateDocument('catalogue', item.$key, {
																	'principalIngredient': item.principalIngredient,
																})
															}
														});
													}
												})
										}
										// 
										// loader.dismiss();
										// Swal.fire('¡Listo!', 'Tu Brick ha sido actualizado', 'success');
										// this.modalController.dismiss();
									})
								}
							})
						// 
						await new Promise(r => setTimeout(r, 2000));
						loader.dismiss();
						this.SwalService.fire('¡Listo!', 'Tu producto ha sido actualizado', 'success');
						this.modalController.dismiss();
					});
				})
			})
		} else {
			//console.log("el brick es nuevo")
			this.loader().then(loader => {
				loader.present().then(() => {
					//console.log(typeof (this.brick))
					// return
					this.brick.category = this.category.$key //'Jyybci7CzlaspCik9Y8h'
					this.brick.countryCode = this.auth.countryCode
					this.brick.storeKey = this.auth.storeKey
					this.brick.storeCode = this.auth.storeCode
					this.brick.visible = true
					this.brick.type = 'brick'
					this.api.addBrickMixology(this.brick, this.ingredients, this.recommended, this.brick_image.src).then(data => {

						loader.dismiss();
						this.SwalService.fire('¡Listo!', 'Tu producto ha sido agregado', 'success');
						this.modalController.dismiss();

					});
				})
			})
		}
	}


}
