import { Component, Input, OnInit } from '@angular/core';
import { FunctionsService } from 'src/app/services/functions/functions.service';

@Component({
    selector: 'app-table-products-supplier',
    templateUrl: './table-products-supplier.component.html',
    styleUrls: ['./table-products-supplier.component.scss'],
})
export class TableProductsSupplierComponent implements OnInit {
    searchBrick = "";
    searchSupplier = ""
    searchDate = ""
    bricks: any;
    @Input() bricksAux: any = [];
    @Input() currency: any = "";
    
    constructor(private functionsService: FunctionsService) { }

    ngOnInit() {
        this.bricks = this.bricksAux;
    }
    filterProducts(value: string, name: string) {
        this.bricksAux = this.functionsService.filterArray(
            value,
            this.bricks,
            [name]
        );
    }
}
